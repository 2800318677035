import React from 'react'
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image";
import '../styles/contact.scss';

const Contact = () => {

    return (
        <Layout>
            <main>
                <div className="connect">
                    <h1>How can you connect with us?</h1>
                    <span>We support our customers through professional applications, first-class service and
                        reliable local service.</span>
                </div>

                <div className="grid auto-fit">
                    <div className="contact">
                        <StaticImage className="contact-icon" src='../images/address-pin.png' alt="main"/>
                        <h5> MAIN OFFICE</h5>
                        <span className="span">111 NE 1st St, 8th Floor,<br/> 88114 Miami,<br />FL 33132,USA</span><br/>
                    </div>
                    <div className="contact"><StaticImage className="contact-icon" src='../images/call.png' alt="contact"/>
                        <h5>PHONE NUMBER</h5>(+1) (410) 202-8844
                    </div>
                    <div className="contact"><StaticImage className="contact-icon" src='../images/envelope.png' alt="email"/>
                        <h5>EMAIL ADDRESS</h5>
                        <span className="span">hello@dd.zone</span>
                    </div>
                </div>
            </main>
            <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.853683800784!2d-80.1939301844405!3d25.
                            775395983630716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b69ebd6f0959%3A0x2d129695a4c85fa6!2s111%20NE%201st%20St%208th%20Floor
                            %2C%20Miami%2C%20FL%2033132%2C%20USA!5e0!3m2!1sen!2sin!4v1656679441315!5m2!1sen!2sin" frameborder="0"
                        style={{border:0}} allowfullscreen></iframe>
            </div>
        </Layout>
    )}

export default Contact
